/**
 * @file standalone/basic-carousel-formatter.scss
 *
 * \brief Library file - shared styles for basic carousel formatter
 *
 */

@import '../theme-bootstrap';

.carousel-formatter {
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
  &.carousel-formatter--full-width {
    max-width: 100%;
  }
}

.basic-carousel-formatter {
  position: relative;
  .carousel-controls {
    top: 35%;
    @include breakpoint($medium-up) {
      top: 50%;
    }
    .slick-prev,
    .slick-next {
      &:before {
        width: 35px;
        height: 35px;
        @include breakpoint($medium-up) {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .carousel-dots {
    .slick-dots {
      bottom: auto;
    }
  }
  .basic-carousel {
    display: flex;
    &:not(.slick-initialized) {
      .basic-carousel__slide {
        display: none;
        width: 100%;
        &:first-of-type {
          display: block;
        }
      }
    }
    .slick-list {
      flex: 0 0 100%;
    }
  }
}

.carousel-slick {
  .basic-carousel__slide {
    padding: 0 5px;
    @include breakpoint($medium-up) {
      padding: 0 42px;
    }
  }
  .basic-carousel-formatter {
    position: relative;
    .carousel-controls {
      top: 35%;
      @include breakpoint($medium-up) {
        top: 50%;
      }
      .slick-prev,
      .slick-next {
        &::before {
          width: 35px;
          height: 35px;
        }
      }
      .slick-prev {
        #{$ldirection}: 0;
      }
      .slick-next {
        #{$rdirection}: 0;
      }
    }
    .slick-dots {
      bottom: 0;
      li {
        button {
          &::before {
            background: $color-grey-tier;
            border: 0;
            height: 10px;
            width: 10px;
          }
        }
        &.slick-active {
          button {
            &::before {
              background: $color-off-black;
            }
          }
        }
      }
    }
    .basic-carousel {
      padding-bottom: 15px;
    }
    .carousel-copy {
      padding: 0 5px;
    }
  }
}
